import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //state中定义数据
  state: {
    carrerTouScroll: {
      height: '',//滚动的距离
      detailId: '',//作品id
    },

  },
  //mutations  操作state数据
  mutations: {
    setCarrerTouScroll(state, disdance) {  // 滚动距离
      state.carrerTouScroll.height = disdance
    },
    // 详情页关闭改变作品id
    changeDid(state, newValue) {
      state.detailId = newValue
    }
  },
  //使用getters  有的不加这句也可以，但是有时候不加就不行，所以还是加上吧
  getters: {
    getCarrerTou: state => state.carrerTouScroll
  }
})
