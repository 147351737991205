import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/cultural',
    name: 'Cultural',
    component: () => import('../views/cultural/cultural.vue'),
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine/mine.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('../views/handleEdit/edit.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail/detail.vue'),
  },
  {
    path: '/picture',
    name: 'Picture',
    component: () => import('../views/picture/picture.vue')
  },
  {
    path: '/invest',
    name: 'Invest',
    component: () => import('../views/invest/invest.vue')
  },
  {
    path: '/argument',
    name: 'Argument',
    component: () => import('../views/argument/argument.vue')
  }

]
const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
})

export default router