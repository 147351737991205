import request from '@/api/request'
// 个人主页喜欢列表
export function getLikes(id) {
  return request({
    url: `/like/likes?userId=${id}`,
    method: 'get'
  })
}
// 根据id获取用户信息
export function userInfo(id) {
  return request({
    url: `/user/userInfo?id=${id}`,
    method: 'get'
  })
}
// 保存修改用户信息
export function saveUserInfo(data) {
  return request({
    url: `/user/saveUserInfo`,
    method: 'post',
    data
  })
}
