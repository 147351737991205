import request from '@/api/request'
// 注册
export function register(data) {
  return request({
    url: `/user/register`,
    method: 'post',
    data
  })
}
// 登录
export function login(data) {
  return request({
    url: `/user/login`,
    method: 'post',
    data
  })
}
// 忘记密码
export function saveByForgetPassword(data) {
  return request({
    url: `/user/saveByForgetPassword`,
    method: 'post',
    data
  })
}
// 发送短信验证码
export function sendCode(tel) {
  return request({
    url: `/sms/sendCode?phone=${tel}`,
    method: 'get'
  })
}
// 忘记密码发送短信验证码
export function sendCodeByForget(tel) {
  return request({
    url: `/sms/sendCodeByForget?phone=${tel}`,
    method: 'get'
  })
}
