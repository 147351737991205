import request from '@/api/request'
// 获取随即两个文创列表
export function getCreativeList() {
  return request({
    url: `/works/indexCulturalList`,
    method: 'get'
  })
}
// 获取随即四个学生作品
export function getWorksList() {
  return request({
    url: `/works/indexStuWorksList`,
    method: 'get'
  })
}