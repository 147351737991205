<template>
  <div class="topNav" :style="{ backgroundColor: bgColor }">
    <!-- pc/piad端导航栏 -->
    <div class="box">
      <div class="left">
        <svg-icon style="width:30px;height:30px" icon-class="logo" />
        <!-- <img src="../assets/images/AGlogo.png" alt="" /> -->
        <div :class="['cul', { activeLine: $route.path == '/' }]">
          <a class="navItem" href="/">首页</a>
          <span class="line"></span>
        </div>
        <div :class="['cul', { activeLine: $route.path == '/cultural' }]">
          <a class="navItem" href="/cultural">文创</a>
          <span class="line"></span>
          <div class="selectNav">
            <div
              :class="['item', { 'active': currentIndex == item.id }]"
              v-for="item in children"
              :key="item.id"
            >
              <a :href="'/cultural?type=' + item.id" @click="this.navShow = false">{{ item.name }}</a>
              <i
                :class="['el-icon-right', 'rightShow', { 'rightActive': currentIndex == item.id }]"
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <i class="el-icon-search" style="color:rgba(255, 255, 255, 0.7)"></i> -->
        <div class="vip">
          <a href="/invest">
            <svg-icon style="width:17px;height:15px;margin-right:5px" icon-class="vip" />
            <div>开通会员</div>
          </a>
        </div>
        <div class="handle" v-if="!isLogin">
          <span @click="goLogin('pc')">登录</span>&nbsp;&nbsp;&nbsp;
          <span>|</span>&nbsp;&nbsp;
          <span @click="goRegister('pc')">注册</span>
        </div>
        <div class="mine" v-else>
          <img v-if="imgUrl" style="width:24px;height:24px;border-radius: 50%;" :src="imgUrl" alt />
          <img
            v-else
            style="width:24px;height:24px;border-radius: 50%;"
            src="../assets/images/defaultImg2.png"
            alt
          />
          <div class="inner">
            <a href="/mine">我的喜欢</a>
            <a href="/edit">个人资料</a>
            <a href="/invest">我的会员</a>
            <div @click="loginOut">退出登录</div>
          </div>
        </div>
      </div>
      <!-- 登录/注册弹框 -->
      <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="480px">
        <el-tabs v-model="activeName" v-if="!forget">
          <el-tab-pane class="login" label="登录" name="first">
            <input v-model="loginForm.phoneNumber" type="text" placeholder="请输入手机号码" />
            <input v-model="loginForm.password" :type="typePwd" placeholder="请输入密码" />
            <i class="el-icon-view" @click="showPwd"></i>
            <div class="forget" @click="forgetPasword">忘记密码？</div>
            <button
              :class="['loginBtn', { btnActive: loginForm.phoneNumber && loginForm.password }]"
              @click="handleLogin"
            >登录</button>
            <div class="tips">
              注册登录即表示同意
              <span>
                <a href="/argument?id=1">《AG网站使用条款》</a>
              </span>
              <span>
                <a href="/argument?id=2">《AG个人信息保护政策》</a>
              </span>
            </div>
          </el-tab-pane>
          <el-tab-pane class="register" label="注册" name="second">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item>
                <el-upload
                  class="avatar-uploader"
                  action="https://api.culture-alchiguru.com/culture/tenCos/upload-img-tencent-cos"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                  <svg-icon class="img" v-else icon-class="defaultImg" />
                </el-upload>
              </el-form-item>
              <el-form-item prop="username">
                <el-input v-model="form.username" placeholder="请设置昵称" />
              </el-form-item>
              <el-form-item prop="phoneNumber">
                <el-input v-model="form.phoneNumber" @input="numChange" placeholder="请输入手机号码" />
              </el-form-item>
              <el-form-item prop="code">
                <div class="code">
                  <el-input
                    @change="checkCode"
                    style="width:65%"
                    v-model="form.code"
                    placeholder="请输入验证码"
                  />
                  <button
                    v-if="showCode == 0"
                    @click="getcode"
                    type="button"
                    style="width:30%"
                    :class="['btn', { activeBtn: sureSend }]"
                  >获取验证码</button>
                  <button v-if="showCode == 1" disabled type="button" style="width:30%" class="btn">
                    重新获取
                    {{ count }}
                  </button>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input show-password v-model="form.password" placeholder="请输入密码" />
              </el-form-item>
              <el-form-item>
                <button
                  type="button"
                  @click="handleRegister"
                  :class="['resBtn', { btnActive: form.username && form.phoneNumber && form.code && form.password }]"
                >注册</button>
                <div class="tips">
                  注册登录即表示同意
                  <span>
                    <a href="/argument?id=1">《AG网站使用条款》</a>
                  </span>
                  <span>
                    <a href="/argument?id=2">《AG个人信息保护政策》</a>
                  </span>
                </div>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
        <div v-else class="register forgetBox">
          <div class="back">
            <i class="el-icon-back" @click="forget = false"></i>
          </div>
          <div class="title">忘记密码</div>
          <el-form ref="forgetForm" :model="forgetForm" :rules="rules">
            <el-form-item prop="phoneNumber">
              <el-input v-model="forgetForm.phoneNumber" @input="numFchange" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item prop="code">
              <div class="code">
                <el-input
                  @change="checkFcode"
                  style="width:65%"
                  v-model="forgetForm.code"
                  placeholder="请输入验证码"
                />
                <button
                  v-if="showFcode == 0"
                  @click="getFcode"
                  type="button"
                  style="width:30%"
                  :class="['btn', { activeBtn: sureFSend }]"
                >获取验证码</button>
                <button
                  v-if="showFcode == 1"
                  type="button"
                  style="width:30%"
                  class="btn"
                >重新获取 {{ fCount }}</button>
              </div>
            </el-form-item>
            <el-form-item prop="oldPassword">
              <el-input show-password v-model="forgetForm.oldPassword" placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item prop="password">
              <el-input show-password v-model="forgetForm.password" placeholder="请确认密码" />
            </el-form-item>
            <el-form-item>
              <button
                type="button"
                @click="handleForget"
                :class="['resBtn', { btnActive: forgetForm.phoneNumber && forgetForm.code && forgetForm.oldPassword && forgetForm.password }]"
              >确认修改</button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <!-- 移动端导航栏 -->
    <div class="mobBox">
      <div :class="['innerBox', { bg: navShow || loginModal }]">
        <svg-icon style="width:30px;height:30px" icon-class="logo" />
        <!-- <img src="../assets/images/AGlogo.png" alt="" /> -->
        <a v-if="!navShow" href="javascript:void(0);" @click="openNav('open')">
          <svg-icon style="width:30px;height:30px" icon-class="icon" />
        </a>
        <a v-else href="javascript:void(0);" @click="openNav('close')">
          <svg-icon style="width:30px;height:30px" icon-class="close" />
        </a>
        <!-- <img v-if="!navShow" @click="openNav('open')" src="../assets/images/icon.png" alt="" /> -->
        <!-- <img class="img" v-else @click="openNav('close')" src="../assets/images/close.png" alt="" /> -->
      </div>
      <div class="innerNav" v-if="navShow" @touchmove.prevent.stop>
        <!-- <div class="search">
          <img class="img" src="../assets/images/search.png" alt="" />
          <input type="text" placeholder="搜索作品" />
        </div>-->
        <div class="line"></div>
        <div class="sort">
          <div :class="{ active: $route.path == '/' }">
            <a href="/" @click="navShow = false">首页</a>
          </div>
          <div :class="{ active: $route.path == '/cultural' }">
            <a href="/cultural" @click="navShow = false">文创</a>
            <van-icon v-if="show" @click="handleShow" name="arrow-down" size="15" />
            <van-icon v-else @click="handleShow" name="arrow-up" size="15" />
          </div>
          <div class="innerSort" v-show="!show">
            <div
              :class="['item', { 'itemActive': currentIndex == item.id }]"
              v-for="item in children"
              :key="item.id"
            >
              <a :href="'/cultural?type=' + item.id" @click="navShow = false">{{ item.name }}</a>
            </div>
          </div>
        </div>
        <div :class="['bottom']">
          <div class="handle" v-if="!isLogin">
            <svg-icon class="img" style="margin-right:5px" icon-class="default2" />
            <span @click="goLogin('mobile')">登录</span>
            <span>/</span>
            <span @click="goRegister('mobile')">注册</span>
          </div>
          <div v-else>
            <a href="/mine" @click="navShow = false" class="user">
              <img
                v-if="imgUrl"
                style="width:24px;height:24px;border-radius: 50%;"
                :src="imgUrl"
                alt
              />
              <img
                v-else
                style="width:24px;height:24px;border-radius: 50%;"
                src="../assets/images/defaultImg2.png"
                alt
              />
              <span v-if="userForm.username" style="margin-left:5px">{{ userForm.username }}</span>
            </a>
          </div>
          <div class="vip">
            <a href="/invest">
              <svg-icon style="width:17px;height:15px;margin-right:5px" icon-class="vip" />
              <div>开通会员</div>
            </a>
          </div>
        </div>
      </div>
      <!-- 登录/注册页面 -->
      <div v-if="loginModal" class="loginBox" @touchmove.prevent.stop>
        <el-tabs v-model="activeName" v-if="!forget">
          <el-tab-pane class="login" label="登录" name="first">
            <input v-model="loginForm.phoneNumber" type="text" placeholder="请输入手机号码" />
            <input v-model="loginForm.password" :type="typePwd" placeholder="请输入密码" />
            <i class="el-icon-view" @click="showPwd"></i>
            <div class="forget" @click="forget = true">忘记密码？</div>
            <button
              :class="['loginBtn', { btnActive: loginForm.phoneNumber && loginForm.password }]"
              @click="handleLogin"
            >登录</button>
            <div class="tips">
              注册登录即表示同意
              <span>
                <a href="/argument?id=1">《AG网站使用条款》</a>
              </span>
              <span>
                <a href="/argument?id=2">《AG个人信息保护政策》</a>
              </span>
            </div>
          </el-tab-pane>
          <el-tab-pane class="register" label="注册" name="second">
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item>
                <el-upload
                  class="avatar-uploader"
                  action="https://api.culture-alchiguru.com/culture/tenCos/upload-img-tencent-cos"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                >
                  <img v-if="form.avatar" :src="form.avatar" class="avatar" />
                  <img v-else src="../assets/images/defaultImg2.png" alt />
                </el-upload>
              </el-form-item>
              <el-form-item prop="username">
                <el-input v-model="form.username" placeholder="请设置昵称" />
              </el-form-item>
              <el-form-item prop="phoneNumber">
                <el-input v-model="form.phoneNumber" @input="numChange" placeholder="请输入手机号码" />
              </el-form-item>
              <el-form-item prop="code">
                <div class="code">
                  <el-input
                    @change="checkCode"
                    style="width:65%"
                    v-model="form.code"
                    placeholder="请输入验证码"
                  />
                  <button
                    v-if="showCode == 0"
                    type="button"
                    @click="getcode"
                    style="width:30%"
                    :class="['btn', { activeBtn: sureSend }]"
                  >获取验证码</button>
                  <button v-if="showCode == 1" disabled type="button" style="width:30%" class="btn">
                    重新获取
                    {{ count }}
                  </button>
                </div>
              </el-form-item>
              <el-form-item prop="password">
                <el-input show-password v-model="form.password" placeholder="请输入密码" />
              </el-form-item>
              <el-form-item>
                <button
                  type="button"
                  @click="handleRegister"
                  :class="['resBtn', { btnActive: form.username && form.phoneNumber && form.code && form.password }]"
                >注册</button>
              </el-form-item>
            </el-form>
            <div class="tips">
              注册登录即表示同意
              <span>
                <a href="/argument?id=1">《AG用户协议》</a>
              </span>
              <span>
                <a href="/argument?id=2">《AG隐私协议》</a>
              </span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div v-else class="register forgetBox">
          <div class="back">
            <i class="el-icon-back" @click="forget = false"></i>
          </div>
          <div class="title">忘记密码</div>
          <el-form ref="forgetForm" :model="forgetForm" :rules="rules">
            <el-form-item prop="phoneNumber">
              <el-input @input="numFchange" v-model="forgetForm.phoneNumber" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item prop="code">
              <div class="code">
                <el-input
                  @change="checkFcode"
                  style="width:65%"
                  v-model="forgetForm.code"
                  placeholder="请输入验证码"
                />
                <button
                  v-if="showFcode == 0"
                  @click="getFcode"
                  type="button"
                  style="width:30%"
                  :class="['btn', { activeBtn: sureFSend }]"
                >获取验证码</button>
                <button
                  v-if="showFcode == 1"
                  type="button"
                  style="width:30%"
                  class="btn"
                >重新获取 {{ fCount }}</button>
              </div>
            </el-form-item>
            <el-form-item prop="oldPassword">
              <el-input show-password v-model="forgetForm.oldPassword" placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item prop="password">
              <el-input show-password v-model="forgetForm.password" placeholder="请确认密码" />
            </el-form-item>
            <el-form-item>
              <button
                type="button"
                @click="handleForget"
                :class="['resBtn', { btnActive: forgetForm.phoneNumber && forgetForm.code && forgetForm.oldPassword && forgetForm.password }]"
              >确认修改</button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  register,
  login,
  sendCode,
  sendCodeByForget,
  saveByForgetPassword
} from '@/api/register/index'
import { userInfo } from '@/api/mine/index'
import jwtDecode from 'jwt-decode'
export default {
  name: 'TopNav',
  data() {
    return {
      navIndex: 0,
      show: true,
      children: [
        {
          name: '全部',
          id: '0'
        },
        {
          name: '漫画',
          id: '1'
        },
        {
          name: '文创品',
          id: '2'
        },
        {
          name: '学生作品',
          id: '3'
        }
      ],
      id: '', //用户id
      navShow: false, //移动端导航页显示
      loginModal: false, //移动端登录页面显示
      dialogVisible: false, //pc登录弹框
      activeName: 'first',
      loginForm: {}, //登录表单
      form: {}, //注册表单
      forgetForm: {}, //忘记密码表单
      forget: false,
      sureSend: false, //发送验证码状态
      sureFSend: false, //忘记密码验证码
      codeCheck: false, //注册验证码合格状态
      fcodeCheck: false, //忘记密码验证码合格状态
      showCode: 0,
      showFcode: 0,
      count: '',
      fCount: '',
      timer: null,
      isLogin: localStorage.getItem('token'),
      imgUrl: '',
      userForm: {},
      typePwd: 'password',
      rules: {
        phoneNumber: [
          { required: true, trigger: 'blur', message: '手机号不能为空' },
          {
            required: true,
            trigger: 'blur',
            message: '手机号格式不正确',
            pattern: '^1[3-9][0-9]{9}$'
          }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' },
          {
            required: true,
            trigger: 'blur',
            message: '密码为6-16个英文和数字字符',
            pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$'
          }
        ],
        oldPassword: [
          { required: true, trigger: 'blur', message: '密码不能为空' },
          {
            required: true,
            trigger: 'blur',
            message: '密码为6-16个英文和数字字符',
            pattern: '^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$'
          }
        ],
        username: [
          { required: true, trigger: 'blur', message: '昵称不能为空' },
          {
            required: true,
            trigger: 'blur',
            message: '昵称为1-10个字符',
            pattern: '^[\u4e00-\u9fa5_a-zA-Z0-9.\\()\\（）]{1,10}$'
          }
        ]
      }
    }
  },
  props: {
    bgColor: {
      type: String,
      default: '#151515'
    },
    currentIndex: {
      type: String,
      default: null
    },
    img: {
      type: Boolean,
      default: null
    }
  },
  created() {
    this.getInit()
  },
  watch: {
    img(oal, val) {
      console.log(oal, val)
      if (val !== oal) {
        this.getUserInfo()
      }
    }
  },
  methods: {
    openNav(sign) {
      if (sign == 'open') {
        this.navShow = true
      } else {
        this.navShow = false
        this.loginModal = false
      }
    },
    handleShow() {
      this.show = !this.show
    },
    // 检验注册验证码
    checkCode(e) {
      var check = /^[0-9]{6}$/
      var code = check.test(e)
      if (code) {
        this.codeCheck = true
      } else {
        this.$message.error('验证码格式不正确')
      }
    },
    // 检验忘记密码验证码
    checkFcode(e) {
      var check = /^[0-9]{6}$/
      var code = check.test(e)
      if (code) {
        this.fcodeCheck = true
      } else {
        this.$message.error('验证码格式不正确')
      }
    },
    // pc登陆弹框出现
    goLogin(sign) {
      this.loginForm = {}
      this.form = {}
      this.forgetForm = {}
      if (sign == 'pc') {
        this.dialogVisible = true
        this.activeName = 'first'
        this.forget = false
      } else if (sign == 'mobile') {
        this.navShow = false
        this.loginModal = true
        this.activeName = 'first'
        this.forget = false
      }
    },
    // pc注册弹框出现
    goRegister(sign) {
      this.loginForm = {}
      this.forgetForm = {}
      this.form = {}
      if (sign == 'pc') {
        this.dialogVisible = true
        this.activeName = 'second'
      } else if (sign == 'mobile') {
        this.navShow = false
        this.loginModal = true
        this.activeName = 'second'
      }
    },
    // 点击忘记密码
    forgetPasword() {
      this.forget = true
      this.loginForm = {}
      this.form = {}
      this.forgetForm = {}
    },
    // 判断手机号是否正确
    numChange(e) {
      var check = /^1[3-9][0-9]{9}$/
      var tel = check.test(e)
      if (tel) {
        this.sureSend = true
      } else {
        this.sureSend = false
      }
    },
    // 判断忘记密码手机号是否正确
    numFchange(e) {
      var check = /^1[3-9][0-9]{9}$/
      var tel = check.test(e)
      if (tel) {
        this.sureFSend = true
      } else {
        this.sureFSend = false
      }
    },
    // 手机验证码倒计时
    getcode() {
      this.$refs.form.validateField('phoneNumber', (errMsg) => {
        if (errMsg) {
          return
        } else {
          sendCode(this.form.phoneNumber).then((res) => {
            const { code, message } = res.data
            if (code == 200) {
              const times = 60 // 倒计时时间
              if (!this.timer) {
                this.count = times
                this.showCode = 1
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= times) {
                    this.count--
                  } else {
                    this.showCode = 0
                    this.sureSend = true
                    clearInterval(this.timer)
                    this.timer = null
                  }
                }, 1000)
              }
              this.$message.success('验证码已发送')
            } else if (message == '该账号已注册') {
              this.sureSend = false
              this.$message.error(message)
            } else {
              this.$message.error(message)
            }
          })
        }
      })
    },
    // 忘记密码验证码
    getFcode() {
      // this.sureFSend=false
      this.$refs.forgetForm.validateField('phoneNumber', (errMsg) => {
        if (errMsg) {
          return
        } else {
          sendCodeByForget(this.forgetForm.phoneNumber).then((res) => {
            const { code, message } = res.data
            if (code == 200) {
              const times = 60 // 倒计时时间
              if (!this.timer) {
                this.fCount = times
                this.showFcode = 1
                this.timer = setInterval(() => {
                  if (this.fCount > 0 && this.fCount <= times) {
                    this.fCount--
                  } else {
                    this.showFcode = 0
                    this.sureFSend = true
                    clearInterval(this.timer)
                    this.timer = null
                  }
                }, 1000)
              }
              this.$message.success('验证码已发送')
            } else {
              this.$message.error(message)
            }
          })
        }
      })
    },
    // 确认注册
    handleRegister() {
      if (this.form.code && this.codeCheck) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            register(this.form).then((res) => {
              const { code, message } = res.data
              if (code == 200) {
                this.$message.success('注册成功')
                this.form = {}
                this.activeName = 'first'
              } else {
                this.$message.error(message)
              }
            })
            this.dialogVisible = false
          } else {
            console.log('error submit!!')
          }
        })
      }
    },
    // 登录
    handleLogin() {
      var checkTel = /^1[3-9][0-9]{9}$/
      var tel = checkTel.test(this.loginForm.phoneNumber)
      var checkPassword = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
      var pass = checkPassword.test(this.loginForm.password)
      if (tel && pass) {
        login(this.loginForm).then((res) => {
          const { code, message, obj } = res.data
          if (code === 200) {
            // 存储token
            localStorage.setItem('token', obj)
            this.$message.success('登录成功')
            this.getInit()
            window.history.go(0)
            this.loginModal = false
            this.dialogVisible = false
            this.isLogin = true
            this.$emit('chooseData', this.isLogin)
          } else {
            this.$message.error(message)
          }
        })
      } else {
        this.$message.error('手机号或密码格式不正确')
      }
    },
    // 登出
    loginOut() {
      console.log(123)
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      this.isLogin = false
      var token = localStorage.getItem('token')
      if (!token) {
        this.$message.success('退出成功')
        this.$emit('chooseData', this.isLogin)
        window.history.go(0)
      }
    },
    // 忘记密码修改密码
    handleForget() {
      if (this.forgetForm.code && this.fcodeCheck) {
        this.$refs.forgetForm.validate((valid) => {
          if (valid) {
            if (this.forgetForm.password == this.forgetForm.oldPassword) {
              saveByForgetPassword(this.forgetForm).then((res) => {
                const { code, message } = res.data
                if (code == 200) {
                  this.$message.success('修改成功')
                  this.dialogVisible = false
                  this.activeName = 'first'
                } else {
                  this.$message.error(message)
                }
              })
            } else {
              this.$message.error('两次密码不一致')
            }
          } else {
            console.log('error submit!!')
          }
        })
      }
    },
    // 头像上传成功
    handleAvatarSuccess(res) {
      const { code, obj, message } = res
      if (code == 200) {
        this.$set(this.form, 'avatar', obj)
      } else {
        this.$message.error(message)
      }
    },
    // 头像上传之前
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png'
      // const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    // 导航栏点击
    // culturalKind(sign) {
    // this.navShow = false
    // if (this.$route.path == '/cultural') {
    //   this.$emit("chooseKind", sign)
    // } else {
    //   this.$router.push({
    //     name: `Cultural`,
    //     params: {
    //       type: sign
    //     }
    //   })
    // }
    // },
    // 根据id获取用户信息
    getUserInfo() {
      userInfo(this.id).then((res) => {
        const { code, obj, message } = res.data
        if (code == 200) {
          this.imgUrl = obj.avatar
          this.$set(this, 'userForm', obj)
        } else {
          this.$message.error(message)
        }
      })
    },
    getInit() {
      var token = localStorage.getItem('token')
      if (token) {
        const decode = jwtDecode(token)
        this.id = decode.id
        // 存储用户信息
        localStorage.setItem('userInfo', JSON.stringify(decode))
        this.getUserInfo()
      }
    },
    // 显示密码
    showPwd() {
      if (this.loginForm.password) {
        this.typePwd = this.typePwd === 'password' ? 'text' : 'password'
      }
    }
  }
}
</script>

<style scoped>
.topNav {
  width: 100%;
  height: 62px;
}

.box {
  position: relative;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.mobBox {
  display: none;
}

.left {
  width: 174px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.box .left .navItem,
.box .left .cul {
  position: relative;
  height: 62px;
  width: 50px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-align: center;
  overflow: hidden;
}

.box .left .navItem {
  line-height: 60px;
  cursor: pointer;
  font-size: 16px;
}

.box .left .cul .line {
  display: block;
  width: 0;
  height: 2px;
  background-color: #ea4c74;
}

.box .left .cul.activeLine .line {
  width: 100%;
}

.box .left .cul.activeLine .navItem {
  color: #fff;
}

.left .cul .selectNav {
  position: absolute;
  top: 50px;
  left: -50px;
  width: 180px;
  height: 175px;
  padding: 19px 24px;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #ffffff;
  color: #0d0c22;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left .cul .selectNav div {
  cursor: pointer;
}

.box .left .enter {
  display: none;
}

.left .cul .selectNav .item:hover .enter {
  display: block;
}

.box .left .cul:hover {
  overflow: inherit;
}

.box .left .cul:hover .selectNav {
  opacity: 1;
}

.left .selectNav .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.left .selectNav .item .rightShow {
  opacity: 0;
}

.left .selectNav .active {
  color: #ff5967;
}

.left .selectNav .item:hover {
  color: #ff5967;
}

.left .selectNav .item .rightActive {
  opacity: 1;
}

.left .selectNav .item:hover .rightShow {
  opacity: 1;
}

.right {
  /* width: 107px; */
  height: 54px;
  display: flex;
  justify-content: center;
  /* justify-content: flex-start; */
  align-items: center;
  font-size: 16px;
}

.right span {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}

.right .el-icon-search {
  margin-right: 20px;
}

.right .vip a {
  width: 93px;
  height: 100%;
  font-family: MicrosoftYaHei;
  color: #e0bb71;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.right .mine {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.right .mine:hover {
  overflow: inherit;
}

.right .mine:hover .inner {
  opacity: 1;
  cursor: pointer;
}

.right .mine .inner {
  position: absolute;
  top: 50px;
  left: -50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  flex-wrap: wrap;
  width: 164px;
  height: 162px;
  background-color: #fff;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 19px 24px;
  box-sizing: border-box;
  opacity: 0;
}

.right .mine .inner div,
.right .mine .inner a {
  color: #0d0c22;
  font-size: 12px;
}

::v-deep .el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.3);
}

::v-deep .el-dialog {
  border-radius: 12px;
}

::v-deep .el-dialog__headerbtn {
  top: 6px;
  right: 6px;
}

::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

::v-deep .el-tabs__active-bar {
  background-color: #ff5967 !important;
  bottom: 7px;
}

::v-deep .el-tabs__item {
  color: #6e6d7a;
  font-size: 17px;
}

::v-deep .el-tabs__item:hover {
  color: #0d0c22;
}

::v-deep .el-tabs__item.is-active {
  color: #0d0c22;
}

::v-deep .el-tabs__nav-scroll {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

::v-deep .el-dialog__body {
  padding: 0px 30px 40px;
  box-sizing: border-box !important;
  font-size: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #5e5e5e;
  font-size: 21px;
}
::v-deep .el-form {
  width: 100%;
}

::v-deep .el-form-item__content:first-child {
  text-align: center;
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

::v-deep .el-form-item__error {
  color: #ff5967;
  font-size: 9px;
  top: 18px;
  right: 18px;
  text-align: right;
  width: fit-content;
  left: unset;
}

::v-deep .el-input__suffix {
  top: 6px;
  right: -2px;
}

.login {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.login input,
::v-deep .el-input__inner {
  width: 100%;
  height: 52px;
  line-height: 52px;
  background: #f7f7f7;
  border-radius: 6px;
  border: none;
  margin-top: 10px;
  font-size: 14px;
  color: #0d0c22;
}

.login input {
  text-indent: 1em;
}

.login .el-icon-view {
  position: relative;
  top: -25px;
  left: 45%;
  cursor: pointer;
}

.login .forget {
  width: 100%;
  text-align: right;
  font-size: 10px;
  color: #0d0c22;
  margin-top: 5px;
  cursor: pointer;
}

.login .loginBtn,
.register .resBtn {
  width: 100%;
  height: 52px;
  line-height: 52px;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  background: #ff5967;
  margin-top: 30px;
  opacity: 0.4;
  font-size: 16px;
}

.login .btnActive,
.register .btnActive {
  opacity: 1;
}

.login .tips,
.register .tips {
  font-size: 8px;
  color: #6e6d7a;
  margin-top: 30px;
}

.register .tips {
  margin-top: 8px;
}

.register {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.register img,
.register .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.register .code {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register .code .btn {
  border: none;
  height: 52px;
  border-radius: 6px;
  background: #d9d9d9;
  font-size: 14px;
  color: #ffffff;
  margin-top: 15px;
}

.register .code .btn.activeBtn {
  background: #ff5967;
}

::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #cbcfd5;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cbcfd5;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cbcfd5;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #cbcfd5;
}

.forgetBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.forgetBox .back {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 20px;
  cursor: pointer;
  color: #6e6d7a;
}

.forgetBox .title {
  font-size: 16px;
  color: #0d0c22;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .box {
    width: 1210px;
  }

  .mobBox {
    display: none;
  }
  .register img,
  .register .img {
    width: 80px;
    height: 80px;
  }
  ::v-deep .el-tabs__item {
    font-size: 22px;
  }

  .login input,
  ::v-deep .el-input__inner {
    height: 52px;
    line-height: 52px;
    font-size: 14px;
    margin-top: 15px;
  }
  .login .forget {
    font-size: 14px;
  }

  .forgetBox .title {
    font-size: 22px;
  }
  ::v-deep .el-form-item__error {
    top: 31px;
  }
  .right .mine .inner div,
  .right .mine .inner a {
    font-size: 14px;
  }
}

/* 笔记本端 992<screen<1200*/
@media (max-width: 1200px) and (min-width: 992px) {
  .box {
    width: 80%;
  }
}

/* pad端 768<screen<992*/
@media (max-width: 992px) and (min-width: 768px) {
  .box {
    width: 80%;
  }
}

/* 移动端 screen<768*/
@media (max-width: 768px) {
  .box {
    display: none;
  }

  .mobBox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
  }

  .innerBox {
    padding: 0 20px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .innerBox.bg {
    background-color: #151515;
  }

  .innerBox img {
    width: 28px;
    height: 28px;
  }

  .innerBox img.img {
    width: 18px;
    height: 18px;
  }

  .innerNav {
    width: 100%;
    height: 100vh;
    background: #151515;
    position: relative;
  }

  .innerNav .search {
    position: relative;
    height: 72px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .innerNav .search .img {
    position: absolute;
    top: 30px;
    left: 25px;
  }

  .innerNav .search input {
    width: 100%;
    height: 37px;
    border: 0px;
    outline: none;
    background-color: #252526;
    color: #6d6d6d;
    border-radius: 6px;
    text-indent: 2em;
  }

  .innerNav .line {
    width: 100%;
    height: 1px;
    background-color: #6d6d6d;
  }

  .innerNav .sort {
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
  }

  .innerNav .sort a {
    list-style: none;
    color: rgba(255, 255, 255, 0.7);
  }

  .innerNav .sort .active a {
    color: #ffffff;
  }

  .innerNav .sort div {
    height: 58px;
    line-height: 58px;
    border-bottom: 0.7px solid #6d6d6d;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .innerNav .sort div.innerSort {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    border: none;
  }

  .innerNav .sort .innerSort .item {
    padding-left: 20px;
    width: 95%;
    height: 46px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .innerNav .sort .innerSort .item.itemActive a {
    color: #fff;
  }

  .innerNav .bottom {
    position: fixed;
    /* left: 20px; */
    bottom: 50px;
    width: 100%;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    z-index: 0;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .innerNav .bottom .handle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .innerNav .bottom .vip a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .innerNav .bottom .handle .img {
    width: 36px;
    height: 36px;
  }

  .innerNav .bottom .user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .innerNav .bottom a {
    list-style: none;
    color: rgba(255, 255, 255, 0.7);
  }

  .login .forget {
    font-size: 13px;
  }

  .login input,
  ::v-deep .el-input__inner {
    font-size: 14px;
  }

  ::v-deep .el-dialog__body {
    font-size: 14px;
  }

  ::v-deep .el-tabs.el-tabs--top {
    height: 85%;
  }

  ::v-deep .el-tabs__content {
    height: 100%;
  }

  .mobBox .loginBox {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    position: relative;
    padding: 83px 45px 39px;
    box-sizing: border-box;
  }

  .mobBox .loginBox .login .tips,
  .register .tips {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: 13px;
    color: #6e6d7a;
  }
  .login input,
  ::v-deep .el-input__inner {
    height: 44px;
    line-height: 44px;
  }

  .login .loginBtn,
  .register .resBtn {
    font-size: 14px;
    margin-top: 40px;
    height: 44px;
    line-height: 44px;
  }
  .register .code .btn {
    font-size: 13px;
    height: 44px;
    margin-top: 10px;
  }
}
</style>