<template>
  <div class="home">
    <!-- 顶部导航 -->
    <top-nav class="nav" :bgColor="'rgba(0, 0, 0, 0.3)'"></top-nav>
    <!-- 大图 -->
    <div class="banner">
      <div class="bannerMask">
        <img class="word" src="../../assets/images/word.png" alt />
        <img class="look" @click="goPicture" src="../../assets/images/button.png" alt />
      </div>
    </div>
    <!-- 中间内容 -->
    <div class="middle">
      <div class="main">
        <!-- 漫画 -->
        <div class="part1">
          <div class="title">
            <div>漫画</div>
            <a :href="'/cultural?type=0'">
              <div class="btn">查看更多</div>
              <div class="btnMask"></div>
            </a>
          </div>
          <div class="content">
            <div class="item" v-for="(item, index) in culturalList" :key="index">
              <a :href="'/detail?id=' + item.id">
                <div class="img">
                  <img :src="item.cover" alt />
                  <div class="itemMask"></div>
                </div>
                <div class="itemTitle">《{{ item.title }}》</div>
                <div class="itemDesc">{{ item.description }}</div>
              </a>
            </div>
          </div>
        </div>
        <!-- 学生作品 -->
        <div class="part2">
          <div class="title">
            <div>精选文创</div>
            <a :href="'/cultural?type=0'">
              <div class="btn">查看更多</div>
            </a>
          </div>
          <div class="content">
            <div class="el" v-for="(el, index) in worksList" :key="index">
              <a :href="'/detail?id=' + el.id">
                <div class="elImg">
                  <img
                    mode="center"
                    style="width:100%; height:100%; border-radius: 6px;"
                    :src="el.cover"
                    alt
                  />
                  <div class="item2Mask"></div>
                </div>
                <div class="elSort">
                  <div class="dot"></div>
                  <div>{{ el.title }}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="bgWorks">
          <img class="pcImg" src="../../assets/images/works.png" alt />
          <img class="mobImg" src="../../assets/images/worksH5.png" alt />
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="pcFoot">
      <div class="code">
        <img src="../../assets/images/code.jpg" alt />
      </div>
      <div class="desc">关注微信公众号</div>
      <div class="line">
        <img
          style="margin-right:5px;vertical-align: middle;"
          src="../../assets/images/safe.png"
          alt
        />
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012824"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
        >
          <img src style="float:left;" />
          <span>浙公网安备33010802012824号</span>
        </a>
        <span style="margin: 0 18px">|</span>
        <span>网络文化经营许可证</span>
        <span style="margin: 0 18px">|</span>
        <span>Copyright © 2024</span>
        <a href="https://beian.miit.gov.cn" target="_Blank">浙ICP备20021846号-2</a>
        <span style="margin: 0 10px">阿基古鲁版权所有</span>
      </div>
      <div class="line">
        <span>地址：杭州市滨江区六和路368号海创基地北二楼D2017</span>
        <span style="margin: 0 18px">|</span>
        <span>邮箱：ag@alchiguru.com</span>
        <span style="margin: 0 18px">|</span>
        <span>文化市场监督电话：112308</span>
      </div>
      <div class="line">本网站为阿基古鲁教育科技有限公司文漫创作网；为了您的健康，浏览漫画及其他内容时，请合理安排时间，享受健康生活</div>
    </div>
    <div class="mobFoot">
      <div class="top">
        <div class="name">邮箱/E-MAIL</div>
        <div class="content">alchiguru123@163.com</div>
        <div class="name">地址/ADDRESS</div>
        <div class="content">杭州市滨江区六和路368号滨江海创园北楼D2017</div>
        <div class="name">关注微信公众号</div>
        <img
          style="width: 123px; height: 122px; margin-top: 10px"
          src="../../assets/images/code.jpg"
          alt
        />
        <div class="line"></div>
      </div>
      <div class="bottom">
        <span>阿基古鲁教育科技有限公司</span>
        <span>|</span>
        <span>版权所有</span>
        <span>|</span>
        <span>网络文化经营许可证</span>
        <div>
          <span>Copyright © 2020</span>
          <a href="https://beian.miit.gov.cn" target="_Blank">浙ICP备20021846号-1</a>
        </div>
        <div>
          <span>文化市场监督电话：112308</span>
        </div>
        <div style="width:300px;margin:0 auto; padding:20px 0;">
          <img src="../../assets/images/safe.png" alt />
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012824"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
          >
            <img src style="float:left;" />
            <p
              style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;"
            >
              浙公网安备
              33010802012824号
            </p>
          </a>
        </div>
        <div>本网站为阿基古鲁教育科技有限公司文漫创作网；为了您的健康，浏览漫画及其他内容时，请合理安排时间，享受健康生活</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCreativeList, getWorksList } from '@/api/home/index'
import TopNav from '@/components/topNav.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      culturalList: [],
      worksList: [],
      show: 'none',
      scrollTop: 0
    }
  },
  components: {
    TopNav
  },
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    getList() {
      // 随机两条文创品
      getCreativeList().then((res) => {
        const { code, obj, mesage } = res.data
        if (code == 200) {
          this.culturalList = obj
        } else {
          this.$message(mesage)
        }
      })
      // 随机四条学生作品
      getWorksList().then((res) => {
        const { code, obj, mesage } = res.data
        if (code == 200) {
          this.worksList = obj
        } else {
          this.$message(mesage)
        }
      })
    },
    // 点击查看更多
    handleMore(sign) {
      this.$router.push({
        name: 'Cultural',
        params: {
          type: sign
        }
      })
    },
    // 前往详情页
    // goDetail(id) {
    //   this.$router.push({
    //     name: `Detail`,
    //     params: {
    //       id
    //     }
    //   })
    // },
    // 前往查看壁画
    goPicture() {
      this.$router.push('/picture')
    }
  }
}
</script>
<style scoped>
@import './index.css';
</style>
