import axios from "axios";
import qs from "qs";
import { Loading } from 'element-ui';
var host;
// process.env是Nodejs提供的一个API，它返回一个包含用户环境信息的对象。
// 通过NODE_ENV 判断当前环境是生产环境(production)还是开发环境(development) 自动切换不同域名
// 暂时开发环境和生产环境  所以域名暂时一样 
if (process.env.NODE_ENV == "development") {
  //开发环境
  host = "http://192.168.0.64:8890/test"
} else {
  host = "https://api.culture-alchiguru.com/culture/"
}
//实例
var instance = axios.create({
  //基础地址
  baseURL: host,
  timeout: 5 * 1000
})

//请求拦截器
var loading;
instance.interceptors.request.use(config => {
  //qs处理post参数 
  if (config.method.toLocaleLowerCase() == 'post') {
    config.data = qs.stringify(config.data);
  }
  //开启加载loading....
  loading = Loading.service({
    lock: true,
    text: '努力加载中....',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255)'
    // background: 'rgba(0, 0, 0, 0.7)'
  });

  return config;
}, err => {
  return Promise.reject(err)
})

//响应拦截器
instance.interceptors.response.use(response => {
  //关闭loading
  loading.close();
  return response;

}, err => {
  loading.close();
  return Promise.reject(err)
})

export default instance;